@import "./../mixins.scss";

.Select2{
    --input-field-border-active: #ccc;
    --text-placeholder: #eee;
    --input-field-border-active: #ccc;
    --input-field-border-focus: #bbb;
    --dropdown-text: #ccc;
    --text-placeholder: #ccc;

    --dropdown-border:  #ccc;
    --dropdown-background: #fff;

    --dropdown-background-inactive-item: #ddd;
    --dropdown-background-hover: var(--accent);
    --dropdown-background-selection: var(--accent);

    --radius: 20px;
    --input-padding-y: 0.5em;
    --input-padding-x: 1.2em;

    height: 40px;
    position: relative;
    font-size: 14px;

    .Select2__hidden-input{
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .Select2__input,
    .Select2__input-wrapper{
        height: 40px;
    }
    .Select2__placeholder,
    .Select2__input{
        height: 40px;
        border-radius: var(--radius);
        border: 1px solid var(--input-field-border-active);
        // box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.08);
        padding-left: var(--input-padding-x);



        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        padding-right: 10px;
        display: flex;
        align-items: center;

    }
    .Select2__placeholder{
        color: var(--text-placeholder);
        display: none;
        padding-right: 1em;
        font-weight: 400;
    }
    .Select2__input{
        cursor: pointer;
    }

    &.Select2--focused{
        .Select2__placeholder,
        .Select2__input{
            border: 1px solid var(--input-field-border-focus);
        }
    }
    &.isEmpty{
        .Select2__input{
            display: none;
        }
        .Select2__placeholder{
            display: flex;
            align-items: center;
        }
    }
    &.isEmpty.autocomplete{
        .Select2__input{
            display: block;
        }
        .Select2__placeholder{
            display: none;
        }
    }

    .Select2__arrow{
        position: absolute;
        top: 50%;
        right: var(--input-padding-x);
        transition: 333ms;
        transform-origin: 50% 50%;
        color: var(--input-field-border-active);
        fill: var(--input-field-border-active);
        // border-color: #999 transparent transparent;
        // border-style: solid;
        // border-width: 5px 5px 2.5px;
        height: 0;
        width: 0;
        // position: relative;
        svg{
            transform: translate(-50%,-50%);
            width: 15px;
            height: 15px;
            display: block;
        }

    }



    &.is-focused:not(.isOpen) > .Select2-control{
        border: 1px solid var(--input-field-border-focus);
        box-shadow: none;
    }

    &.isDisabled{

        .Select2__placeholder,
        .Select2__input{
            box-shadow: none;
            background: var(--input-field-background-inactive);

            border: 1px solid var(--input-field-border-inactive);
            // opacity: .5;
            cursor: not-allowed;
            // color: var(--text-placeholder);
        }
        .Select2__arrow{
            // display: none;
            color: var(--input-field-border-inactive);
            fill: var(--input-field-border-inactive);
        }
    }


    .Select2__footer,
    .Select2__description{
        font-size: 12px;
        margin-bottom: 2px;
        color: var(--dropdown-text);
    }
    .Select2__option{
        padding: var(--input-padding-y) var(--input-padding-x);
        padding-bottom: 0;
        margin-bottom: 1px;
        cursor: pointer;
        color: var(--dropdown-text);

        // transition: 222ms;

        display: flex;
        flex-direction: row;
        min-height: 40px;

        &.Select2__option--placeholder{
            color: var(--text-placeholder);
        }


        .Select2__option-label{
            flex-grow: 1;
        }
        .Select2__option-button{
            width: 16px;
            height: 16px;
            align-self: center;
            svg{
                height: 16px;
                width: 16px;
                color: red;
            }
        }

        &[disabled],
        &.disabled{
            cursor: not-allowed;
            opacity: 0.7;
            background: var(--dropdown-background-inactive-item);

        }

        &:not([disabled]):not(.disabled):hover{
            // background: var(--dropdown-background-hover);
            color: var(--accent);
        }
        &.focused:not([disabled]):not(.disabled){
            // background: var(--dropdown-background-hover);
            color: var(--accent);
            // color: #fff;
        }
        &.active.focused:not([disabled]):not(.disabled),
        &.active{
            color: var(--accent);
            // background: var(--dropdown-background-selection);
        }
    }


    .Select2__dropdown{
        display: none;
        position: relative;
        border-width: 1px;
        border-style: solid;
        border-radius: 0;
        border-color: var(--dropdown-border);
        box-shadow:  0 1px 2px rgba(10,10,10,.08);
        background: var(--dropdown-background);
        width: 100%;
        padding: 0px;
        text-align: left;
        max-height: 200px;
        box-shadow: #00000069 -2px 2px 20px -7px;
        padding-top: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .Select2__dropdown-wrapper{
        max-height: calc(200px - 20px);
        overflow-y: auto;
        overflow-x: none;
        
    }

    &.Select2--width-2{
        .Select2__dropdown{
            width: 232px;
        }
    }

    &.isOpen{
        z-index: 100;
        .Select2__placeholder,
        .Select2__input{
            border-radius: var(--radius) var(--radius) 0 0;

        }
        .Select2__dropdown{
            display: block;
            border-radius: 0 0 var(--radius) var(--radius);

            z-index: 2;

        }
        .Select2__arrow{
            transform: rotate(180deg);
        }
    }


    &.tiny{

        font-size: 12px;
        line-height: 1.2;
        height: 18px;
        width: 100%;
        .Select2__input-wrapper{
            height: 18px;
        }
        .Select2__input,
        .Select2__placeholder{
            height: 18px;
            font-size: inherit;
        }
        .Select2__dropdown{
            width: 100%;
        }
    }
    &.medium{

        width: 100%;

        .Select2__input-wrapper {
            height: 31px;
        }

        .Select2__dropdown{
            width: 100%;
            font-size: 12px;
            line-height: 1.2;
        }
    }
}

.form-control.select-replaced,
.select-replaced{
    opacity: 0;
    position: relative;
    z-index: -1;
    margin-bottom: -40px;
    display: block;

    height: 40px;
}




.SizeOption__content{
    display: flex;
    vertical-align: baseline;
    align-items: baseline;
    width: 100%;
    border-bottom: 1px solid var(--dropdown-border);
    padding-top: calc(var(--input-padding-y) * 1);
    padding-bottom: calc(var(--input-padding-y) * 2);
    font-size: 11px;
    line-height: 1;
    transition: 333ms;

    pointer-events: none;

    .SizeOption__name{
        flex-grow: 1;
        // font-weight: bold;
        color: #000;
        width: 18%;
        font-size: 14px;
        line-height: 1;

        @include media-breakpoint-down(sm){
            font-size: 13px;
        }
    }
    .SizeOption__promo{
        // width: ;
        
    }
    .SizeOption__pack{
        width: 9.5ch;
    }
    .SizeOption__price{
        width: 9ch;
        text-align: right;
    }
    a{
        text-decoration: none;
        font-weight: bold;
        color: var(--accent);
    }

}
.Select2__footer .SizeOption__content{
    border-bottom: 0;
}
.Select2__option:not(:disabled):hover .SizeOption__content{
    .SizeOption__name{
        color: var(--accent);
    }
}