@import "/src/mixins";

.bubble-list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 1rem;
    .BubbleButton{
        margin-right: 10px;
        margin-bottom: 10px;
    }
    @include media-breakpoint-down(lg){     
        &.bubble-list--full{
            .BubbleButton{
                width: 100%;
    
            }
        }
    }
}

.BubbleButton__checked{
    opacity: 0;
    transition: 333ms;
    position: relative;
    top: 3px;
    left: -5px;
    svg{
        display: block;
        height: 18px;
        width: 18px;
    }
}

.BubbleButton{
    --bb-padding: 0.3em 1em;
    
    /* #FFFFFF */
    padding: var(--bb-padding);
    background: #FFFFFF;
    border: 1px solid #fff;
    box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.25);
    border-radius: 10px;

    display: flex;
    align-items: baseline;
    justify-content: space-between;

    color: $body-color;

    transition: 333ms;

    cursor: pointer;
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:not(:disabled):hover{
        border-color: var(--accent);
    }
    &.checked{
        border-color: var(--accent);
        .BubbleButton__checked{
            opacity: 1;
            animation: zoomIn 333ms;
        }
        .BubbleButton__label{
            left: 0;
        }
    }
}

.BubbleButton__label{
    position: relative;
    transition: 333ms;
    left: -20px;
    margin-right: auto;
}
.BubbleButton__info{
    
    font-style: normal;
    font-weight: 500;
    font-size: 11px;

    color: #AAAAAA;
    padding-left: 1em;
}