// Custom.scss
// Option B: Include parts of Bootstrap


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1460px
) !default;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";



// scss-docs-start theme-color-variables
$primary:       #000;
$secondary:     #fff;
$body-color: #000;

$btn-border-radius: 50px;
$btn-border-radius-sm:  50px;
$btn-border-radius-lg:  50px;
