@import "/src/mixins";

@keyframes zoomIn{
    from{
        opacity: 0;
        transform: scale(.9);
    }
}

.ReportModal{
    

    .Modal{
        animation: zoomIn 333ms;
    }


}
.ReportModal__preview{
    display: flex;
    justify-content: center;
    position: relative;

    
    @include media-breakpoint-up(md){
        justify-content: flex-start;
        
        padding-right: 20px;
        display: flex;
        img{
            object-fit: cover;
        }
    }
}
.ReportModal__header{
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    font-weight: 500;
    .ReportModal__header-icon{
        flex-grow: 0;
        flex-shrink: 0;
        width: 30px;
        position: relative;
        top: -2px;
    }
}