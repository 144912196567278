@import "/src/mixins";

.Modal__overlay_video {
    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    z-index: 121;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    display: none;

    &.is-open{
        display: flex;
    }
}

.Modal {
    display: flex;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 40px);
    
    position: relative;
    padding-bottom: 2rem;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 60px -10px;

    @include media-breakpoint-up(md) {
        max-height: calc(100% - var(--padding) * 2);
        max-width: 900px;
    }
}

.Modal__content_video {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    width: 100%;
    max-height: 100%;
    text-align: center;

    h5 {
        margin-top: 2rem;
    }
}

.Modal__close_video {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    transition: 333ms;
}

.ArModal {
    align-items: center;
    text-align: center;
    .Modal__close{
        width: 30px;
        height: 30px;
        padding: 10px;
        top: 0;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    .Modal{
        min-width: 280px;
        min-height: 300px;
    }
}

.watch-video-modal-title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .watch-video-modal-title {
        font-size: 24px;
        line-height: 30.24px;
        font-weight: 500; 
    }
}

.yellow-text-background {
    background-color: #FFD124;
    border-radius: 5rem;
    padding: 0 0.5rem; 
    width: fit-content; 
}