
.SmallCard{
    color: inherit;
    --shadow-color: rgba(0, 0, 0, 0.2);

    &:not([disabled]):hover{
        --shadow-color: rgba(0, 0, 0, 0.3) ;
    }

    background-color: #fff;
    border: 0;
    width: 100%;

    &[disabled]{
        cursor: not-allowed;
    }
    
    .SmallCard__inner{
        box-shadow: var(--shadow-color) 0 0 9px -2px, #4bb9e800 0 0 0 2px;
        transition: 333ms;
        padding-bottom: 100%;
        position: relative;
        border-radius: 10px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .checked-icon{
        position: absolute;
        bottom: 8px;
        right: 10px;
        display: none;
        svg{
            display: block;
        }
    }

    &.checked{
        .SmallCard__inner{
            box-shadow: var(--shadow-color) 0 0 9px -2px, #4BB8E8 0 0 0 2px;
        }
        .checked-icon{
            display: block;
            animation: zoomIn 333ms;
        }
    }
}


.SmallCard__desc{
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 1.4;
    text-align: center;
    margin-top: 0.5em;
}