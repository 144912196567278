@use '/src/mixins.scss';

.TableSummary{
    margin-bottom: 1rem;
    .TableSummary__total{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        width: 100%;
        margin-bottom: 1rem;

        &.last{
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 1rem;
        }
    }
    .TableSummary__total-name{
        flex-grow: 1;
    }
    .TableSummary__total-price{
        flex-shrink: 1;
        text-align: right;
    }
   

    ol{
        list-style: none;
        display: block;
        padding-left: 0;
        margin-left: 0;
    }
    ol ol{
        padding-left: 0;
        margin-left: 1rem;
    }
    .TableSummary__item{
        display: flex;
        align-items: baseline;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-gray-dark);
        margin: 0.5rem 0
    }
    .TableSummary__item-label{
        
        flex-grow: 1;
    }
    .TableSummary__item-desc{
        font-size: 11px;
        color: #AAAAAA;
        margin-left: 1em;
        display: inline-flex;
    }
    .TableSummary__item-price{
        text-align: right;
        flex-shrink: 1;
    }
}