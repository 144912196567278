@import '/src/mixins.scss';


.PdfDownload{
    display: flex;
    text-decoration: none;
    /* #FFFFFF */

    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.25);
    border-radius: 10px;
    
    padding: 12px 30px;
    transition: 333ms;

    margin-bottom: 1rem;
    
    &:not(:disabled):hover{
        box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.6);

    }

    .PdfDownload__icon,
    .PdfDownload__content{
        p{
            margin-bottom: 0;
        }
    }
    .PdfDownload__icon{
        color: var(--accent);
        margin-right: 25px;
    }
    .PdfDownload__content{
        flex-grow: 1;
        width: auto;   
        min-height: 1em;
        padding-top: 0.2em;

    }

    .PdfDownload__note{

        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.4);
        min-height: 1em;
        padding-top: 0.3em;
    }
    .PdfDownload__title{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;

        color: #000000;
    }
    .PdfDownload__btn{
        flex-shrink: 1;
        flex-grow: 0;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--accent);
        svg{
            margin-right: 0.5em;
        }
    }
    @include media-breakpoint-down(md){
        .PdfDownload__btn-label{
            display: none;
        }
    }
}