@use '/src/mixins.scss';


.FileManager{
    display: flex;
    position: relative;
    text-decoration: none;
    /* #FFFFFF */

    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.25);
    border-radius: 10px;
    
    padding: 20px 20px;
    transition: 333ms;

    margin-bottom: 1rem;
    
    &:not(:disabled):hover{
        box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.6);

    }
    .ProgressBar{
        margin-top: 0.5rem;
        margin-bottom: 0;
        width: 100%;
    }

    .FileManager__icon,
    .FileManager__content{
        p{
            margin-bottom: 0;
        }
    }
    .FileManager__icon{
        color: #4BB8E8;
    }
    &.error .FileManager__icon{
        color: #E45826;
        opacity: 0.5;
    }
    .FileManager__content-inner{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    .FileManager__icon{
        margin-right: 20px;
        svg{
            // margin-right: 0.5em;
            display: block;
            width: 40px;
            height: 52px;
        }
    }
    .FileManager__content{
        flex-grow: 1;
        width: auto;   
        min-height: 1em;
        padding-top: 0.2em;

    }

    .FileManager__note{

        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.4);
        min-height: 1em;
        padding-top: 0.3em;
    }
    .FileManager__title{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        overflow-wrap: anywhere;

        color: #000000;
    }
    .FileManager__btn{
        // flex-shrink: 1;
        // flex-grow: 0;
        // align-self: center;
        // display: flex;
        // align-items: center;
        // justify-content: flex-end;
        // color: var(--accent);
        position: absolute;
        top: 5px;
        right: 5px;
    }
        .FileManager__btn-cancel{
            width: 22px;
            height: 22px;
            border-radius: 22px;
            margin: 2px;
            padding: 5px;
            background-color: rgba(244, 244, 244, 0.87);
            color: #C4C4C4;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            transition: 333ms;

            svg{
                display: block;
                flex-shrink: 0;
                flex-grow: 0;
            }
            &:not(:disabled):hover{
                background-color: var(--accent);
                color: #fff;
            }
        }
}