@use "/src/mixins.scss";

.ProgressBar{
    height: 5px;
    border-radius: 10px;
    position: relative;
    background-color: rgba(200, 200, 200, 0.2);
    margin: 1rem 0;
    .ProgressBar__progress{
        width: 0;
        height: 5px;
        position: absolute;
        left: 0;
        top: 0;
        transition: 111ms;
        background-color: var(--accent);
        border-radius: 10px;


    }
}