
.OverlayCover{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // z-index: 111;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.7);
}