html, body{
    font-family: 'Plus Jakarta Sans', 'Open Sans', sans-serif;
}

.fs-14{
 font-size: 14px;
}

.fs-12{
 font-size: 12px;
}

.form-header{
    
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;

    color: #000000;

    margin-top: 1em;
    margin-bottom: 0.7em;
}

.text-gray{
    color: var(--text-gray);
}

.marked{
    display: inline-block;
    border-radius: 20px;
    padding-left: 0.2em;
    padding-right: 0.2em;
    background-color: var(--yellow);
}
.marked-link{
    color: var(--accent);
}


.h3{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;

}

.link-smaller{
    font-size: 12px;
    color: var(--accent);
    text-decoration: none;
    &:hover{
        color: var(--accent);
    }
}