
@import '/src/mixins';

.SwiperList{
    // display: flex;
    // overflow-x: scroll;
    // overflow-y: hidden;
    // max-width: 100%;
    // .SmallCard{
    //     min-width: 150px;
    // }
    .SmallCard{
        max-width: 200px;
    }



    // --sw-padding: 8px;
    // @include media-breakpoint-up(lg){
    //     --sw-padding: 8px;
    // }

    // overflow: hidden;
    // display: block;

    // // padding: var(--sw-padding);
    
    // // margin-left: calc(-1 * var(--sw-padding));
    // // width: calc(100% + var(--sw-padding) * 2);
    // // .swiper-wrapper{

    // // }

    // .swiper{
    //     width: 100%;
    //     max-width: 100%;
    // }
    
    
}