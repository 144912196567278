.InlineWarning {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    text-align: center;

    animation: slideInTop 333ms ease-in-out;

    color: #979797;
    // display: flex;
    // align-items: flex-start;

    svg {
        margin-right: 5px;
        flex-shrink: 0;
        flex-grow: 0;
    }
}