@import "/src/mixins";

.Modal__overlay{

    --padding: 1.3rem;

    @include media-breakpoint-up(lg){
        --padding: 60px;
    }

    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    z-index: 121;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    display: none;
    
    // @include media-breakpoint-up(md){
    //     align-items: center;
        
    // }

    &.is-open{
        display: flex;
    }
}

.Modal {
    display: flex;
    max-width: calc(100% - 20px);
    min-width: 120px;
    max-height: calc(100% - 40px);
    padding: var(--padding);
    padding-right: calc(var(--padding) * 1/3);
    padding-left: calc(var(--padding) * 1/3);
    margin: 0 1.5rem;
    
    position: relative;
    padding-bottom: 2rem;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 60px -10px;

    @include media-breakpoint-up(md){
        max-height: calc(100% - var(--padding) * 2);
        max-width: 900px;
    }
}
.Modal__content{
    
    padding-right: calc(var(--padding) * 2/3);
    padding-left: calc(var(--padding) * 2/3);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;


    h5{
        margin-top: 2rem;
    }
}
.Modal__footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
        
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.99%);
    border-radius: 0px 0px 20px 20px;
    padding: 1rem var(--padding);

}

.Modal__close{
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    transition: 333ms;
    &:hover{
        transform: scale(1.2);
    }
}



.ArModal{
    align-items: center;
    text-align: center;
    .Modal__close{
        width: 30px;
        height: 30px;
        padding: 10px;
        top: 0;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    .Modal{
        min-width: 280px;
        min-height: 300px;
    }
}