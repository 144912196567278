@import "/src/mixins";


.DefaultDropzone{
    --padding: 25px;

    margin-bottom: 1rem;

    .DefaultDropzone__title{
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0.5em;

    }
    .DefaultDropzone__note{
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        color: var(--text-gray);
    }
    
    .dropzone {
        position: relative;
        > div{
            padding: var(--padding);
            background: rgba(197, 197, 197, 0.2);
           
            
            border: 1px dashed #00000056;
            border-radius: 10px;
    
            p:last-of-type{ margin-bottom: 0; }
    
            .button{
                margin-bottom: .2em;
            }

            
        }
        
        > div:hover,
        > div.active{
             
            
            box-shadow: 0 0 0 var(--accent);
            animation: pulse 888ms infinite;
        }

      
    }

}

