
.btn{
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 12px;
    padding-bottom: 12px;
        
    font-size: 16px;
    line-height: 18px;
    
    @include media-breakpoint-up(md){
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
        line-height: 1.2;
    }

}
.btn.primary{
    background-color: #000;
    color: #fff;
    
    &:hover,
    &:active{
        background-color: #000;
        color: #fff;
    }
    
    &:disabled{
        background: #bbb;
        cursor: not-allowed;
    }

    &.hollow{
        background-color: #fff;
        color: rgb(0, 0, 0);
        border-color: #000;
        
        &:hover,
        &:active{
            background-color: rgba(0, 0, 0, 0.171);
            color: #000;
            border-color: #000;
        }
        &:disabled{
            border-color: #bbb;
            cursor: not-allowed;
        }

    }
}

.btn.btn-lg{
    padding-top: 13px;
    padding-bottom: 13px;
}


.btn-icon{
    border: 0;
    padding: 0;
    background: transparent;
    appearance: unset;
}
.btn-link,
.link{
    display: inline-block;
    background-color: transparent;
    padding: 0;
    border: 0;
    font-style: normal;
    // font-weight: 600;

    /* #4BB8E8 */

    color: var(--accent);
    text-decoration: none;
    border-bottom: 1px dashed transparent;
    svg{
        margin-right: 0.4em;
    }
    
    &:not(:disabled):hover{
        color: var(--accent);
        border-bottom: 1px dashed  var(--accent);

    }
}