
// --dropdown-background: #ffffff;
// --dropdown-border: #d1d1d1;
// --dropdown-text: #333333;
// --dropdown-background-hover: #eeeeee;
// --dropdown-background-selection: #faefc9;
// --dropdown-background-inactive-item: #eeeeee;

@keyframes scaleIn {
    from{
        opacity:0;
        transform: scale(.8);
    }
}

.DropdownPopover{
    --dropdown-background: #fff;





    $spacer: 1;

    text-align: inherit;

    font-size: inherit;
    margin: 0px 0;
    display: inline-block;
    position: relative;

    font-size: 12px;
    color: var(--dropdown-text);

    &.active {
        z-index: 111;
    }

    &.active .DropdownPopover__list{
        display: block;
    }



    .DropdownPopover__button{
        all: initial;
        cursor: pointer;
        display: block;
    
        font-family: inherit;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */
    
        letter-spacing: 0.02em;

    }




    .DropdownPopover__list{


        list-style: none;
        margin: 0;
        vertical-align: middle;
        position: absolute;
        top: calc( 100% + 2px );
        left: 0;
        width: 260px;
        padding: 0px;
        padding-left: 0px;

        box-shadow: rgba(0,0,0,.2) 2px 2px 10px -1px;
        background: var(--dropdown-background);

        border-radius: 5px;
        padding-top: 10px;
        padding-bottom: 10px;


        display: none;
        transform-origin: top left;

        animation: scaleIn 222ms cubic-bezier(0.04, 1.19, 0.5, 1.31) both;




        &:after {
            bottom: 100%;
            left: 5%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: var(--dropdown-background);
            border-width: 5px;
            margin-left: -5px;
        }

        li{
            display: block;
            position: relative;

            padding-left: 0;
            margin-bottom: 0px;

            


            &:not(.checked){
                .outer{
                    stroke: #ddd;
                }
                .inner{
                    fill: #ddd;
                }
            }



            div{
                position: relative;
                // padding-left: (40px + 8px);
                min-height: 21px;
                break-inside: avoid;
                line-height: 1.2;
                padding: 10px 20px;

                display: flex;
                flex-direction: row;
                align-items: center;

                transition: 333ms;
                text-decoration: none;
                color: inherit;
                cursor: pointer;
            

                svg{
                    width: 22px;
                    height: 22px;
                    margin-right: 8px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                &:hover{
                    background: #eee;
                    
                    .outer{
                        stroke: #4BB8E8;
                    }
                    .inner{
                        fill: #4BB8E8;
                    }
                }

            }



        }
    }

    &.position-right{
        .DropdownPopover__list{
            transform-origin: top right;
            left: auto;
            right: 0;
            &:after {
                right: 5px;
                left: auto;
            }
        }


    }
}

