@use '/src/mixins.scss';

.InfoBar{
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    .InfoBar__icon{
        flex-grow: 0;
        flex-shrink: 0;
        width: 35px;
    }
    .InfoBar__content{
                
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        /* identical to box height */

        color: rgba(0, 0, 0, 0.459);

    }
    .InfoBar__title{
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0.3rem;
        margin-top: 0.2rem;

        color: #000000;
    }
}