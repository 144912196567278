@import "/src/mixins.scss";


.AccordionItem{
    --acc-padding: 15px;

    background: #FFFFFF;
    /* box shadow */

    box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.25);
    border-radius: 10px;
    padding: var(--acc-padding);

    margin-bottom: 10px;
    .AccordionItem__title{
        position: relative;
    }

    .AccordionItem__plus-icon{
        color: #000;
        position: absolute;
        right: 5px;
        top: 0;
        transition: 333ms;
        transform-origin: center;
    }
    .checked-icon{
        display: inline-block;
        margin-right: 5px;
        svg{
            width: 18px;
            height: 18px;
            position: relative;
            top: -1px;
        }
        @include media-breakpoint-up(lg){
            display: none;
        }
    }
    
    
    @include media-breakpoint-up(lg){
        margin-bottom: 20px;
        
        box-shadow: none;
        --acc-padding: 0;
        .AccordionItem__plus-icon{
            display: none;
        }
    }

    &.is-open{
        .AccordionItem__plus-icon{
            transform: rotate(45deg);
        }
        .AccordionItem__title{
            padding-bottom: 5px;
        }
    }
    &:not(.is-open){
        .AccordionItem__title .Info{
            display: none;
        }
    }

}
.AccordionItem__title{

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    cursor: pointer;
    .Info{
        position: relative;
        top: -2px;
    }
    
}
.AccordionItem{
    .Collapse{
        

    }
}

