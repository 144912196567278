
.tippy-box{
    background-color: #fff;
    color: #000;
    font-size: 11px;
    box-shadow: rgba(0, 0, 0, 0.37) 0 0 20px -5px;
    padding: 0.8em;
}

.tippy-arrow{
    color: #fff;
}