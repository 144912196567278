@use "/src/mixins.scss";

@import "~bootstrap/scss/bootstrap";
@import "custom";
@import "scss/btn.scss";
@import "scss/bg.scss";
@import "scss/wrappers.scss";
@import "scss/swal.scss";

@import "./components/SummaryList";
body{
  background-color: #fff;
  color: #000;
}

$changeOrientationOn: xl;

/*
 *  Scrollbar
 */

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #fafafa;
}
 
::-webkit-scrollbar
{
    width: 6px;
    height: 6px;
    background-color: #fafafa;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #4BB8E8;
}
html, body, #root{
    height: 100%;
}
html.has-modal,
html.has-modal body{
    overflow: hidden;
}
.App.App--preloading{
    overflow: hidden;
    height: 100%;
}
body{
    overflow-x: hidden;
}
hr{
    background: rgba(0, 0, 0, 0.1);
    opacity: 1;
}
.App{
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.col-form{
    order: 1;
    padding-top: 1rem;
}

@include media-breakpoint-up($changeOrientationOn){

    .col-form{
        order: 0;
        padding-bottom: 30px;
    }
    .step-design{
        .col-form{
            order: 1;
        }
    }
}

.shadow-line{
    box-shadow: #000 0 -5px 10px 0;
    height: 0px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.main-preloader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;

    background-color: rgba(255, 255, 255, 0.918);
    iframe{
        display: block;
        width: 100%;
        height: 100%;
    }
}

.main-content{
    flex-grow: 1;
    display: flex;
    width: 100%;
    >.default-wrapper{
        // display: flex;
        width: 100%;
        >.row{
            height: 100%;
        //    flex-grow: 1;
        }
    }
}
.Render{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    height: 100%;
    position: relative;
    background-color: #fff;
    isolation: isolate;
    .main-preloader{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    iframe{
        z-index: 0;
    }

    html.light-bg &{
        background-color: var(--yellow);
    }

    .Switch{
        position: absolute;
        top: 10px;
        left: 5px;
        color: #fff;
        @include media-breakpoint-up($changeOrientationOn){
            top: 2em;
            left: 2em;
            
        }
    }

    .btn-view-ar{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom 111ms;
        bottom: 20px;
        z-index: 5;
        @include media-breakpoint-up($changeOrientationOn){
            
            // bottom: calc( 20px + var(--render-top-offset));
            bottom: 40px;
        }
    }
    .btn-arrow{
        padding: 10px;
        border: 0;
        background-color: transparent;
    }
    .btn-next,
    .btn-prev{
        position: absolute;
        top: calc(50% - 20px);
        transition: 111ms;
        
        @include media-breakpoint-up($changeOrientationOn){
            // top: calc( 50% + 100px - var(--render-top-offset));
            // top: calc( 50% + 100px - var(--render-top-offset));
            
        }

        &:hover{
            background-color: rgba(255, 255, 255, 0.178);
        }
        svg{
            width: 20px;
            height: 20px;
            color: #000;
            transform-origin: center;
        }

    }
    .btn-next{
        right: 0;
        svg{
            transform: rotate(-90deg);
        }
    }
    .btn-prev{
        left: 0;
        svg{
            transform: rotate(90deg);
        }
    }
    
    .SketchfabRender{
        width: 100%;
        height: calc(45vh);
        overflow: hidden;
        --margin: 50px;
        position: relative;
        @include media-breakpoint-up($changeOrientationOn){
            position: sticky;
            top: 33px;
            // height: calc(100vh - 140px);
            height: calc(100vh - 102px - var(--render-top-offset));

        }
        iframe{
            width: calc(100% + var(--margin) * 2);
            height: calc(100% + var(--margin) * 2);
            position: relative;
            left: calc(-1 * var(--margin) );
            top: calc(-1 * var(--margin) );
            z-index: 0;
        }
           
    }
}

.AppHeader{
    @include media-breakpoint-down(lg){
        position: relative;
        z-index: 6;
        box-shadow: rgba(0, 0, 0, 0.356) 0 5px 10px -8px;
    }

}

.AppHeader__inner{
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.AppHeader__logo{
    margin-right: auto;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;

    img{
        height: 30px;
        width: auto;
        margin-right: .4em;
    }

    @include media-breakpoint-up(lg){
        font-size: 20px;
        img{
            width: auto;
        }
    }

    @include media-breakpoint-down(lg){
        font-size: 10px;
        img{
            width: auto;
            max-width: 51px;
        }
    }
}
.AppHeader__buttons{
    margin-left: 2px;

    display: flex;
    align-items: center;

    .btn-icon{
        margin-left: 1em;
    }
    .DropdownPopover{
        margin-left: 1em;
        .DropdownPopover__button > svg{
            position: relative;
            top: -2px;
        }
    }
}

.PrimaryMenu{
    
    display: none;
    @include media-breakpoint-up(xxl){
        display: block;
    }
    ul{
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        a{
            padding: 0.5em 1em;
            text-decoration: none;
            color: #000;
        }
    }

}



.TabNav{
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
    background-color: #fff;

    box-shadow: rgba(0, 0, 0, 0.356) 0 10px 20px -15px;
    border-bottom: 1px solid #eee;
    position: relative;
    z-index: 5;
    @include media-breakpoint-up($changeOrientationOn){    
        position: sticky;
        top: 0;
        border-bottom: 0;
    }
    
    .default-wrapper{
        width: 100%;
        display: flex;
    }

    &:after{
        content: '';

    }
}

.TabNav__tab{
    border: 0;
    background-color: transparent;
    position: relative;
    color: rgb(0, 0, 0, .4);
    margin-bottom: -2px;
    @include media-breakpoint-up(lg){
        width: 100%;
    }
}

.TabNav__tab-inner{
    display: inline-block;
    
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    position: relative;
    padding: 1.2em 1em 0.8em;
    
    font-size: 14px;
    line-height: 18px;
    @include media-breakpoint-up(lg){
        padding: 0.3em 1em;
        
        font-size: 18px;
        line-height: 23px;
    }

    &:after{
        content: '';
        width: 0%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        height: 2px;
        background-color: #4BB8E8;
        transition: 333ms;
    }
}
.TabNav__tab.active {
    color: #000;

    .TabNav__tab-inner{
        &:after{
            width: 100%;
        }
    }
}


.buttons-row{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 1em;
    margin-bottom: 1em;
}



.error-bar{
    display: flex;
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 1rem;
    .error-bar__icon{
        flex-grow: 0;
        flex-shrink: 0;
        width: 22px;
        margin-right: 10px;
        position: relative;
        top: -3px;   
        .Loader__showbox{
            top: 2px;
        }     
    }

    a{
        color: var(--accent);
        text-decoration: none;
    }
}

.popup{
    display: none;
    overflow: hidden;
    position: fixed;
    z-index: 9999999999999999999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000000b8;
    .phone{
        background-repeat: no-repeat;

        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 330px;
        height: 630px;
        background-image: url('../public/assets/modal/phone.png');
        transform: translate(-50%, -50%);
        padding: 80px 60px;
        text-align: center;
        
        #QrModalCanvas {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
        h1{
            color: #222222;
            font-size: 26px;
            line-height: 1.1;
            font-weight: 300;
            margin-top: 0;
            margin-bottom: 2rem;
        }
        p{
            font-size: 12px;
            line-height: 14px;
            color: #222222;
            margin-bottom: 20px;
        }
    }
}

.relative{
    position: relative;
}

.watch-video-btn {
    margin-right: 1rem;
}

.watch-video-btn {
    @include media-breakpoint-down(lg){
        margin-right: 0.2rem;
    }
}

.header-mobile-btn {
    @include media-breakpoint-down(lg){ 
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 12px;
        line-height: 12px;
    }
}