@import '/src/mixins.scss';

.Switch{
    display: flex;
    align-items: center;

    .Switch__label{
        color: inherit;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        margin-left: 5px;
        margin-right: 5px;
        transition: 333ms;
    }
    .Switch__label.left{
        color: #000;
    }
    .Switch__label.right{
        color: rgba(0, 0, 0, 0.438);
    }
    .Switch__input{
        display: none;
    }

    .Switch__control{
        width: 37px;
        height: 21px;
        background: #f3f1ea8c;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        border-radius: 99px;
        position: relative;

        &:after{
            content: '';
            width: 19px;
            height: 19px;
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 1px;
            left: 1px;
            transition: 333ms;
        }
    }

    .Switch__input:checked ~ .Switch__label{

        color: #fff;
    }
    .Switch__input:checked ~ .Switch__control:after{
        left: calc(100% - 20px);
        color: #fff;
    }
}