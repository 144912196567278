@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes slideInTop {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
        transform: scale(0);
    }
}