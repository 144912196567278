@import "/src/mixins.scss";


.TextureUpload{
    --padding: 30px;

    background: #FFFFFF;
    box-shadow: var(--shadow-1);
    border-radius: var(--radius);
    padding: var(--padding);
    position: relative;
    margin-bottom: 1rem;

    animation: zoomIn 333ms;
    svg{
        display: block;
    }
   

    .TextureUpload__arrow{
        transition: 555ms;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        background-color: var(--render-bg);
        
        border-radius: var(--radius) 0 0 var(--radius);
     
        
    }
    .TextureUpload__remove{
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 5px;
        cursor: pointer;
        transition: 333ms;
        color: #999;

        &:hover{
            transform: scale(1.4);
        }
        
    }

    .TextureUpload__label{
      
    }


    &.active{
        .TextureUpload__arrow{
            opacity: 1;
        }
    }
    &.removing{
        animation: fadeOut 333ms;
    }

    @include media-breakpoint-up(xl){
        

        .TextureUpload__arrow{
            &:after{
                content: '';
                right: 100%;
                top: 50%;
                border: solid transparent;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-right-color: var(--render-bg);
                border-width: 8px;
                margin-top: -8px;
    
            }
            
        }

        
    }

}